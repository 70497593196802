import React from "react";



export const DashboardMain:React.FC = () => {
    return(
        <>
            PLATZHALTER
        </>
    )
}