export const getTmpId = (tmpArray:any[] | undefined = undefined) => {
    let tmpId = -1;

    if (tmpArray !== undefined && tmpArray.length > 0) {
        let tmpIdArray = Math.min(...tmpArray.map(x => x["id"])) -1;

        if (tmpIdArray <= tmpId) {
            tmpId = tmpIdArray -1;
        }

    }

    return tmpId;
}