import React, { useState } from 'react';
import {  Box, CssBaseline, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, DesignServices, HealthAndSafety, Home, Handshake, Logout, Person, Star, PictureAsPdf, Apartment, SupervisorAccount, Inventory, ImportContacts } from '@mui/icons-material';
import AvatarePicture from './AvatarPicture';
import { usePermissions } from '../../contexts/permissions/PermissionContext';


const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean; 
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface INavElement {
    ListItemKey: string;
    Navigate: string;
    Icon: React.ReactElement;
    ListItemText: string;
    Divider?: boolean;
    Group?: string;
    isAdmin?: boolean;
    requiredPermission?: string;
}

export interface IProps {
    children?: React.ReactNode;
}


export const NavBar:React.FC<IProps> = (props) => {
    const theme = useTheme();
    const is_superuser = localStorage.getItem("is_superuser") === "true"
    const [isSidebarOpen,setIsSidebarOpen] = useState(false);
    const [pageName,setPageName] = useState("Home");
    const navigate = useNavigate();
    const [groupArray,setGroupArray] = useState(localStorage.getItem("groups")?.split(",").map(x => x.toLocaleLowerCase()))
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { permissions } = usePermissions();
    console.log(permissions)
    const naviagtionFunc = (targetUrl:string,pageName:string) => {
        setPageName(pageName);
        navigate(targetUrl);
        setIsSidebarOpen(false);
    }


    const menuArray: INavElement[] = [
        {
          ListItemKey: "dashboard",
          Navigate: "/",
          Icon: <Home />,
          ListItemText: "Dashboard",
          Divider: true
        },
        {
          ListItemKey: "projects",
          Navigate: "/projects",
          Icon: <Star />,
          ListItemText: "Projekte",
        },
        {
          ListItemKey: "customers",
          Navigate: "/customers",
          Icon: <Person />,
          ListItemText: "Kunden",
          Divider: true,
          requiredPermission: "view_customer"
        },
        {
          ListItemKey: "listofservices",
          Navigate: "/listofservices",
          Icon: <DesignServices />,
          ListItemText: "Leistungsverzeichnis",
          Divider: true,
          requiredPermission: "view_service",
        },
        {
          ListItemKey: "listofmaterials",
          Navigate: "/listofmaterials",
          Icon: <Inventory />,
          ListItemText: "Materialverzeichnis",
          Divider: true,
          requiredPermission: "view_material",
        },
        {
          ListItemKey: "healthinsurances",
          Navigate: "/healthinsurances",
          Icon: <HealthAndSafety />,
          ListItemText: "Pflegeversicherung",
          requiredPermission: "view_insurance",
        },
        {
          ListItemKey: "partners",
          Navigate: "/partners",
          Icon: <Handshake />,
          ListItemText: "Partner",
          requiredPermission: "view_partner",
        },
        {
          ListItemKey: "property_managment",
          Navigate: "/property_managment",
          Icon: <Apartment />,
          ListItemText: "Hausverwaltung",
          Divider: true,
          requiredPermission: "view_propertymanagement",

        },
        {
          ListItemKey: "roles",
          Navigate: "/roles",
          Icon: <SupervisorAccount />,
          ListItemText: "Rolenverwaltung",
          Divider: true,
          isAdmin: true
        },
        {
          ListItemKey: "templates",
          Navigate: "/templates",
          Icon: <ImportContacts />,
          ListItemText: "Vorlagen",
          //requiredPermission: "view_template",
          Divider: true
        },
      ];

    const generateEntry = () => {
        return menuArray.filter(x => {
          return (
            (!x.isAdmin || is_superuser) && 
            (!x.requiredPermission || (permissions && permissions.includes(x.requiredPermission)))
          );
        }).map(x => (
          <React.Fragment key={x.ListItemKey}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton onClick={() => naviagtionFunc(x.Navigate, x.ListItemText)}>
                <ListItemIcon>{x.Icon}</ListItemIcon>
                <ListItemText primary={x.ListItemText} />
              </ListItemButton>
            </ListItem>
            {x.Divider && <Divider />}
          </React.Fragment>
        ));
      };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed" open={isSidebarOpen}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{
                            marginRight: 5,
                            ...(isSidebarOpen && { display: 'none' }),
                          }}
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap component="div">
                    {pageName}
                    </Typography>

                    <Box display='flex' flexGrow={1} />
                    <Typography sx={{mr: 2}}>v0.2.4</Typography>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Einstellungen öffnen">
                            <IconButton onClick={(event: React.MouseEvent<HTMLElement>)  => setAnchorElUser(event.currentTarget)} sx={{ p: 0 }}>
                                <AvatarePicture/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={() => setAnchorElUser(null)}
                        >
                            <MenuItem onClick={() =>{localStorage.removeItem("token");
                            localStorage.removeItem("is_superuser");
                            localStorage.removeItem("groups");
                            localStorage.removeItem("idUser");
                            navigate("/login") }} >
                                <Typography textAlign="center">Abmelden</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>



            <Drawer
                variant="permanent" 
                open={isSidebarOpen}
                //onClose={() => setIsSidebarOpen(!isSidebarOpen)}
            >
                <DrawerHeader>
                    <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                        {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <Box
                    sx={{width: 250}}
                    role="presentation"
                >
                    <List>
                        {generateEntry()}
                    </List>
                </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {(props.children) && props.children}
            </Box>
        </Box>
    )
}
